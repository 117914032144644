<template>
    <v-row>
           <v-btn
            color="primary"
            dark
            x-large
            @click="dialog=true"
        >
           Add
        </v-btn>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
        >
            <v-card v-if="dialog">
                <v-card-title>
                    <span class="headline my-4">Add broker</span>
                </v-card-title>
                <v-form ref="form">
                <v-card-text class="pt-0 mt-0">
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    label="Name*"
                                    v-model="data.name"
                                    outlined
                                    :error="data.name == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    required
                                    class="mb-0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                  label="Contact numbers*"
                                  v-model="data.contact_number"
                                  :error="data.contact_number == '' && showError"
                                  :rules="[(v) => !!v || 'Field is required']"
                                  outlined
                                  required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="mb-4 grey--text">
                                <small>separated by / i.e 09123456788/09123456789</small>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Email address*"
                                    v-model="data.email"
                                    outlined
                                    :error="data.email == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    required
                                    class="mb-0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="mt-6 mb-2">
                                <v-row
                                    v-for="(item, index) in licenseItems"
                                    :key="`form-${index}`"
                                    class="px-2 pb-2"
                                  >
                                    <v-col class="pa-0 d-flex justify-end">
                                      <v-text-field
                                        label="License*"
                                        v-model="item.name"
                                        hide-details="auto"
                                        :error="item.name == '' && showError"
                                        :rules="[(v) => !!v || 'Field is required']"
                                        outlined
                                        class="mx-1"
                                      ></v-text-field>
                                      <v-btn
                                        v-if="licenseItems.length > 1"
                                        x-small
                                        depressed
                                        fab
                                        color="error"
                                        class="ml-2"
                                        @click="deleteLicense(index)"
                                      >
                                        <v-icon> mdi-close </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                            </v-col>
                            <v-col cols="12 d-flex justify-end">
                                <div class="mt-0 mb-4">
                                  <v-btn color="primary" small depressed @click="addLicense" class="mt-1">
                                    Add more license
                                  </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="12" class="mt-6 mb-2">
                                <v-row
                                    v-for="(item, index) in snsItems"
                                    :key="`form-${index}`"
                                    class="px-2 pb-2"
                                  >

                                    <v-col cols="3" class="pa-0">
                                      <v-select
                                        label="Social media*"
                                        v-model="item.name"
                                        :error="item.name == '' && showError"
                                        :rules="[(v) => !!v || 'Field is required']"
                                        :items="snsList"
                                        hide-details="auto"
                                        outlined
                                      ></v-select>
                                    
                                    </v-col>
                                    <v-col class="pa-0 d-flex justify-end">
                                      <v-text-field
                                        label="Account"
                                        v-model="item.account"
                                        hide-details="auto"
                                        outlined
                                        class="mx-1"
                                        :error="item.account == '' && showError"
                                        :rules="[(v) => !!v || 'Field is required']"
                                      ></v-text-field>
                                      <v-btn
                                        v-if="snsItems.length > 1"
                                        x-small
                                        depressed
                                        fab
                                        color="error"
                                        class="ml-2"
                                        @click="deleteItem(index)"
                                      >
                                        <v-icon> mdi-close </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                            </v-col>
                            <v-col cols="12 d-flex justify-end">
                                <div class="mt-0 mb-4">
                                <v-btn color="primary" small depressed @click="addItem" class="mt-1">
                                    Add another account
                                </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="6" class="mb-6">
                                <v-select
                                    label="Realty"
                                    v-model="data.realty_id"
                                    :items="realtyList"
                                    :item-text="'name'"
                                    :item-value="'id'"
                                    hide-details="auto"
                                    :error="!data.realty_id && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    required
                                    outlined
                                    ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    color="blue accent-4"
                                    counter
                                    placeholder="Add photo(.jpeg, .jpg, .png)"
                                    :error="data.image == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    prepend-icon="mdi-image"
                                    outlined
                                    :show-size="1000"
                                    @change="onFileChange"
                                >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="deep-purple accent-4"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeDialog"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="addBrokerAgent"
                        :loading="loader"
                        :disabled="loader"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

    export default {
        name: 'AddBrokerModal',
        props: ['errors', 'visible'],
        data: () => ({
            showError: false,
            menu: [],
            data: {
              name: "",
              contact_number: "",
              image: null,
              license: [],
              socials: [],
              realty_id: null
            },
            image: null,
            snsList: ['Facebook', 'Youtube', 'Instagram', 'Twitter', 'Whatsapp', 'Viber', 'LinkedIn', 'Tiktok'],
            licenseItems: [ { name: "" } ],
            snsItems: [ { name: null, account: "" } ],
            realtyList: [],
            loader: false,
            dialog: false
        }),
        mounted() {
            this.clearData();
            this.licenseItems.map((item) => {
                return this.formatDate(item.dateFormatted);
            });
            this.getRealtyList();
        },
        computed: {
            computedDateFormatted () {
              return this.formatDate(this.date)
            },
        },
        methods: {
            ...mapActions(["addBroker", "updateBroker", "getRealties"]),
            getRealtyList() {
              this.getRealties()
                .then((response) => {
                    this.realtyList = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
            },
            formatDate (date) {
              if (!date) return null

              const [year, month, day] = date.split('-')
              return `${month}/${day}/${year}`
            },
            parseDate (date) {
                console.log('date', date)
              if (!date) return null

              const [month, day, year] = date.split('/')
              return `${month}/${day}/${year}`
            },
            logEvent() {
              let source = document.querySelector('.cke_source');
              if(source) {
                this.$set(this.data, 'details', source.value)
              }
      		},
            addItem() {
                let item = {
                    name: "",
                    account: "",
                }
                this.snsItems.push(item)
            },
            addLicense() {
                let item = {
                  name: "",
                }
                this.licenseItems.push(item)
            },
            addBrokerAgent() {
                this.loader = true
                let isValid = this.$refs.form.validate()
                let emptyLicense = this.licenseItems.filter((license) => license.name == '');
                if (!isValid && emptyLicense.length > 0) {
                    this.showError = true;
                    this.loader = false
                    return
                }
                this.data.socials = this.snsItems;
                this.data.license = this.licenseItems;
                
                this.addBroker(this.data)
                    .then((response) => {
                        this.loader = false;
                        if(this.image) {
                            let formData = new FormData();
                            formData.append("image", this.image);
                            this.updateBroker({id: response.data.id, formData })
                            .then((responses) => {
                                this.loader = false;
                                this.handleDone()
                            })
                            .catch((error) => {
                                console.log(error);
                                this.loader = false;
                            });
                        } else {
                            this.loader = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader = false;
                    })
            },
            clearData() {
                this.data.name = "";
                this.data.email = "";
                this.data.contact_number = "";
                this.data.license = [];
                this.data.socials = [];
                this.licenseItems = [ { name: "" } ];
                this.snsItems = [ { name: null, account: "" } ];
                this.data.pictures = null;
                this.data.image = null;
                this.image = null;
                this.loader = false
            },
            onFileChange(file) {
                this.data.image = file;
                this.image = file;
            },
            onError() {
                this.loader = false
            },
            deleteLicense(index){
                this.licenseItems.splice(index, 1)
            },
            deleteItem(index){
                this.snsItems.splice(index, 1)
            },
            closeDialog() {
                this.clearData();
                this.dialog = false;
            },
            handleDone() {
                this.dialog = false;
                this.clearData();
                this.$emit('done');
            }
        }
    }
</script>
<style scoped>
    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
</style>
