<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
          class="ml-2"
          small
        >
          View
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <v-avatar size="100" class="mr-2" color="grey">
            <v-img v-if="item.image"
              :src="item.image"
              max-width="200"
              height="200" contain></v-img>
            <span v-else class="white--text text-h4">
              {{item.name.split(" ").map((n)=>n[0]).join("")}}
            </span>
          </v-avatar>
          <div>
            <span class="headline my-4">{{ item.name }}</span>
            <div class="text-body-2">{{ item.email }}</div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-phone</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Contact Numbers</v-list-item-subtitle>
              <v-list-item-title>{{ item.contact_number }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-home-city</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Realty</v-list-item-subtitle>
              <v-list-item-title>{{ item.realty_name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-seal</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>Licenses</v-list-item-subtitle>
              <v-list-item-title
                v-for="(item, index) in item.license"
                :key="`license-${index}`"
              >{{item.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Social media</v-list-item-subtitle>
              <div class="d-flex flex-wrap">
                <a
                  v-for="(item, index) in item.socials"
                  :href="item.account" 
                  target="_blank"  :key="`social-${index}`"
                  class="ma-1">
                  <v-icon v-if="item.name == 'Facebook'" size="40" color="primary">mdi-facebook</v-icon>
                  <v-icon v-if="item.name == 'Youtube'" size="40" color="error">mdi-youtube</v-icon>
                  <v-icon v-if="item.name == 'Instagram'" size="40" color="error">mdi-instagram</v-icon>
                  <v-icon v-if="item.name == 'Twitter'" size="40" color="primary">mdi-twitter</v-icon>
                  <v-icon v-if="item.name == 'Viber'" size="40" color="purple">mdi-phone</v-icon>
                  <v-icon v-if="item.name == 'Whatsapp'" size="40" color="green">mdi-whatsapp</v-icon>
                  <v-icon v-if="item.name == 'LinkedIn'" size="40" color="primary">mdi-linkedin</v-icon>
                  <font-awesome-icon v-if="item.name == 'Tiktok'" icon="fa-brands fa-tiktok" />
                </a>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'ViewBroker',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
<style scoped lang="scss">
.col {
    padding-bottom: 0;
    padding-top: 0;
}
a {
  text-decoration: none;
}
.flex-wrap {
  flex-wrap: wrap;
}
svg {
  height: 32px;
  margin-top: 4px;
}
</style>
