<template>
    <v-container fluid>
        <h1>Brokers</h1>
        <AddBrokerModal class="my-4 ml-2" @done="updateDone"/>
        <v-data-table
          :headers="headers"
          :items="brokers"
          :loading="loading"
        >
          <template v-slot:item.id="{ item }">
            b{{item.id}} 
          </template>
          <template v-slot:item.contacts="{ item }">
            {{item.email}}<br />
            {{item.contact_number}}    
          </template>
          <template v-slot:item.license="{ item }">
            <div 
              v-for="(license, index) in item.license"
              :key="`license-${index}`">
              {{ license.name }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
              <div class="d-flex my-4">
                  <ViewBroker :item="item" />
                  <v-btn color="primary" rounded dark small
                    @click="showUpdate(item)"
                    class="mx-1"
                  >
                    Update
                  </v-btn>
                  <v-btn color="error" rounded dark small
                    @click="showDelete(item)"
                  >
                    Delete
                  </v-btn>
              </div>
          </template>
        </v-data-table>
        <DeleteDialog
          :show="deleteItem"
          :item="activeItem"
          @delete="handleDelete"
          @close="closeDialog"
          title="Delete Broker"
          btnTitle="Delete"/>
        <EditBroker
          :show="updateItem"
          :item="activeItem"
          @delete="handleDelete"
          @close="closeDialog"
          @update="updateDone"
          btnTitle="Delete"/>
    </v-container>
</template>

<script>
import ViewBroker from '../components/modal/ViewBroker.vue';
import AddBrokerModal from '@/components/modal/AddBroker';
import DeleteDialog from '../components/modal/DeleteDialog.vue';
import EditBroker from '../components/modal/EditBroker.vue';
import { mapActions } from 'vuex';
    export default {
        name: 'Broker',
        components: {
          AddBrokerModal,
          DeleteDialog,
          EditBroker,
          ViewBroker
        },
        data() {
          return {
            activeItem: null,
            deleteItem: false,
            updateItem: false,
            items: [
              { text: 'Open' },
              { text: 'Ongoing' },
              { text: 'Closed' },
              { text: 'Canceled' },
            ],
           headers: [
              {text: 'ID', value: 'id'},
              {text: 'Name', value: 'name'},
              {text: 'Contacts', value: 'contacts', sortable: false},
              {text: 'License', value: 'license', sortable: false},
              {text: 'Actions', value: 'actions', class: 'actions', sortable: false}
            ],
            loading: false,
            brokers: [
              {
                id: '01',
                name: 'John Doe',
                position: 'Broker',
                contact_number: '+639151634789',
                licenses: ['DHSUD#10', 'DHSUD#11']
              }
            ],
          }
        },
        mounted() {
          this.getBrokersList();
        },
        methods: {
            ...mapActions(["getBrokers", "deleteBroker"]),
            getBrokersList() {
              this.getBrokers()
                .then((response) => {
                    console.log('response', response)
                    this.brokers = response.data
                    //this.loader = false;
                })
                .catch((error) => {
                    console.log(error);
                    //this.loader = false;
                })
            },
            async handleDelete() {
              await this.deleteBroker({id: this.activeItem.id})
                .then((response) => {
                  this.getBrokersList();
                })
                .catch((error) => {
                  console.log(error);
                })

              this.closeDialog()
            },
            showUpdate(item) {
              console.log('update item', item)
              this.activeItem = item;
              this.updateItem = true;
            },
            showDelete(item) {
              this.activeItem = item;
              this.deleteItem = true;
            },
            closeDialog() {
              this.activeItem = null;
              this.deleteItem = false;
              this.updateItem = false;
            },
            updateDone() {
              this.closeDialog();
              this.getBrokersList();
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
